<!-- eslint-disable @angular-eslint/template/use-track-by-function -->
<mat-toolbar color="primary" class="flex-row mobile-toolbar-wrapper">
  <button mat-icon-button (click)="sidenav().open()" color="accent">
    <mat-icon color="accent">menu</mat-icon>
  </button>

  <div class="flex-col">
    <span class="mobile-toolbar">
      <a class="mobile-toolbar--logo-container flex-row flex-justify-start flex-align-center" routerLink="home">
        <img class="mobile-toolbar--logo" [src]="logo" [alt]="logo" />
      </a>
    </span> @if (topic()) {
    <span class="mobile-menu-topic">{{ topic().topicDescription | titlecase }}</span> }
  </div>
</mat-toolbar>

<mat-toolbar color="primary" class="custom-desktop-toolbar">
  <div class="topRow flex-row flex-justify-space-evenly flex-align-center">
    <mat-form-field id="searchField">
      <mat-label>{{ 'dottnet.menu.ricerca' | translate }}</mat-label>
      <input matInput type="text" minlength="3" [ngModel]="searchText()"
        (ngModelChange)="searchTextInternal.set($event)" (keydown.enter)="searchTextResult()"
        placeholder="{{ 'dottnet.search.toofewcharacters' | translate }}" />
      <button mat-icon-button matSuffix aria-label="Search" [disabled]="!checkMinLength()" (click)="searchTextResult()">
        <mat-icon>search</mat-icon>
      </button>
      <mat-error>{{ 'dottnet.search.toofewcharacters' | translate }}</mat-error>
    </mat-form-field>

    <div class="flex-col">
      <span class="branding flex-row flex-justify-center flex-align-center">
        <a routerLink="home">
          <img class="browser-logo" [src]="logo" [alt]="logo" />
        </a>
      </span> @if (topic()) {
      <span class="menu-topic">{{ topic().topicDescription | titlecase }}</span> }
    </div>

    <div class="flex-row flex-justify-end flex-align-center">
      @if (user()) { @if (user()?.premium) {
      <img class="icona-premium" src="assets/premium.png" /> } }
      <button id="accediButton" mat-button [matMenuTriggerFor]="toolbarUserMenu">
        <span class="flex-row flex-justify-start flex-align-center navigation-button">
          {{ getUserName() }}
          <mat-icon color="accent">arrow_drop_down</mat-icon>
        </span>
      </button>

      <mat-menu #toolbarUserMenu="matMenu" xPosition="before" class="dottnet-menu">
        @for (userMenuItem of userMenuItems; track userMenuItem) {
        <span class="user-menu-item">
          <a mat-menu-item class="navigation-button" [routerLink]="userMenuItem.routerLink"
            (click)="doAction(userMenuItem)">
            {{ userMenuItem.label | translate }}
            @if (userMenuItem.logoutIcon) {
            <mat-icon> power_settings_new </mat-icon>
            }
          </a>
        </span> }
      </mat-menu>
    </div>
  </div>
  <!--SOLO PER DEBUGGARE-->
  <!-- <div fxLayoutAlign="center center" class="debug-div">
    <section *ngIf="authLoginState">LS: {{ authLoginState.toString() }}</section>
    &nbsp;&nbsp;
    <section>
      SI: <span *ngIf="sessionID$ | async as sessionID">{{ sessionID.toString() }}</span>
    </section>
    &nbsp;&nbsp;
    <section *ngIf="loginType$ | async as loginType">LT: {{ loginType }}</section>
  </div> -->
  <!--FINE SOLO PER DEBUGGARE-->

  <hr class="menu-hr" />

  <div>
    @for (item of navigation; track item) { @if (item.subLinks) {
    <button style="min-width: 110px; text-align: left" mat-button [matMenuTriggerFor]="toolbarUserMenu">
      <span class="flex-row flex-justify-start flex-align-center navigation-button">{{ item.label | translate }}
        <mat-icon color="accent">arrow_drop_down</mat-icon>
      </span>
    </button>
    <mat-menu #toolbarUserMenu="matMenu" xPosition="before" class="dottnet-menu">
      @if (user()?.premium && item.link === 'extra') {
      <span class="navigation-button">
        @for (subItem of item.subLinksLogged; track subItem) {
        <a mat-menu-item [routerLink]="[subItem.link]">
          {{ subItem.label | translate }}
        </a>
        }
      </span> } @if (!user()?.premium && item.link === 'extra') {
      <span class="navigation-button">
        @for (subItem of item.subLinks; track subItem) {
        <a mat-menu-item [routerLink]="[subItem.link]">
          {{ subItem.label | translate }}
        </a>
        }
      </span> } @if (item.link !== 'extra') {
      <span class="navigation-button">
        @for (subItem of item.subLinks; track subItem) {
        <a mat-menu-item [routerLink]="[subItem.link]">
          {{ subItem.label | translate }}
        </a>
        }
      </span> }
    </mat-menu>
    } @if (!item.subLinks) {
    <span class="navigation-button">
      <a [routerLink]="[item.link]" style="min-width: 110px" mat-button>
        {{ item.label | translate }}
      </a>
    </span> } }
  </div>
</mat-toolbar>