import { Component, ChangeDetectionStrategy, OnInit, input, signal } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Store } from '@ngrx/store';
import { authLogout } from '../../core/auth/auth.actions';
import { AuthLoginState } from '../../core/auth/auth.models';
import { clearList } from '../../domain/content/content.actions';
import { UserDTO } from '../../domain/user/user.model';
import { openDialog } from '../dialog/dn-dialog.actions';
import { SigninDialogComponent } from '../dialog/signin-dialog/signin-dialog.component';
import { homeLogo, navigation, navigationSideMenu } from '../navigation/navigation.model';
import { infoLogo } from '../navigation/navigation.model';
import { TranslateModule } from '@ngx-translate/core';
import { MatListModule } from '@angular/material/list';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dottnet-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  imports: [RouterLink, MatButtonModule, MatIconModule, MatFormFieldModule, MatInputModule, FormsModule, MatListModule, RouterLinkActive, TranslateModule]
})
export class SidenavComponent implements OnInit {
  // References for enums
  readonly AuthLoginState = AuthLoginState;

  // Input variables
  // Reference to the outer sidenav template reference
  readonly sidenav = input<MatSidenav>(undefined);
  // Reference to the search field
  readonly searchText = input<string>(undefined);
  searchTextInternal = signal(this.searchText);
  readonly topic = input<string>(undefined);
  // Values from app observables
  readonly authLoginState = input<AuthLoginState>(undefined);
  readonly user = input<UserDTO>(undefined);

  // Constants
  homeLogo: string;
  navigation: any;
  navigationSideMenu: any;

  constructor(private store: Store, private route: Router) { }

  ngOnInit(): void {
    this.searchTextInternal = signal(this.searchText);
    this.homeLogo = infoLogo;
    this.navigation = navigation;
    this.navigationSideMenu = navigationSideMenu;
  }

  openSignin() {
    this.sidenav().close();
    this.store.dispatch(
      openDialog({
        content: undefined,
        urlToDownload: undefined,
        urlWithContext: undefined,
        componentType: SigninDialogComponent,
        panelClass: 'dn-dialog-small'
      })
    );
  }

  onLogoutClick() {
    this.store.dispatch(authLogout());
  }

  searchTextResult() {
    if (this.checkMinLength()) {
      // this.store.dispatch(clearList());
      this.route.navigate(['/ricerca', this.searchTextInternal()]);
      this.sidenav().close();
    }
  }
  checkMinLength(): boolean {
    return this.searchTextInternal()?.length >= 3;
  }
}
